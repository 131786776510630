const english = {
  about: "about",
  aboutUs: "About us",
  add: "add",
  addImage: "Add image",
  addToCart: "add to cart",
  amount: "amount",
  author: "author",
  authors: "Authors",
  authorsList: "authors list",
  book: "book",
  bookCover: "Book cover",
  bookTitle: "book title",
  books: "books",
  booksList: "books list",
  back: "back",
  cart: "cart",
  cartInfo: "cart information",
  clientCity: "city",
  clientEmail: "email",
  clientInfo: "client information",
  clientLastName: "last name",
  clientName: "name",
  clientPostalCode: "postal code",
  clientStreetName: "street name",
  clientStreetNumber: "street number",
  clientCityMsg: "Please enter city name",
  clientEmailMsg: "Please enter a valid email adress",
  clientLastNameMsg: "Please enter your last name",
  clientNameMsg: "Please enter your name",
  clientPostalCodeMsg: "Please enter your area postal code",
  clientStreetNameMsg: "Please enter street name",
  clientStreetNumberMsg: "Please enter street number",
  confirm: "confirm",
  contact: "contact",
  content: "Content",
  date: "date",
  delete: "delete",
  depth: "depth",
  description: "Description",
  dimensions: "Dimensions",
  disclaimer: "Disclaimer",
  edit: "edit",
  enterEmail: "enter your email",
  emptyCart: "empty cart",
  error: "error",
  errorMsg:
    "Unfortunately an error occurred, it is being automatically reported",
  free: "Free",
  genInfo: "general information",
  genre: "Genre",
  giftshop: "giftshop",
  giftshopList: "giftshop list",
  hardcover: "Hardcover",
  height: "height",
  home: "home",
  image: "Image",
  images: "Images",
  infoPages: "info pages",
  item: "item",
  language: "Language",
  lastName: "last name",
  link: "link",
  links: "links",
  list: "list",
  message: "message",
  messages: "messages",
  more: "more",
  maxAmount: "Maximum amount to order",
  maxOrder: "Max order",
  name: "name",
  newOrder: "New order recieved",
  news: "news",
  newsList: "news list",
  next: "next",
  noOfPages: "No. of pages",
  notFound: "not found",
  orderHowTo: "How to order",
  order: "order",
  orderNoun: "Order",
  orders: "orders",
  orderTotal: "order total",
  ourBooks: "Our books:",
  ourGiftshop: "Our giftshop:",
  page: "page",
  pageErrMsg:
    " Browser will automatically redirect to previous page, in case it does not, please click 'Back'",
  paperback: "Paperback",
  paymentAndShipping: "Payment and shipping",
  pleaseWait: "please wait",
  pleaseWaitLoad: "Please wait, loading...",
  prev: "prev",
  price: "Price",
  priceTotal: "Price total",
  properties: "Properties",
  postalFee: "Postal fee",
  postalFeeLimit: "No postal fee above",
  publisher: "Publisher",
  purchaseAdress: "enter adress for your purchase",
  rating: "rating",
  remove: "remove",
  reset: "reset",
  review: "review",
  reviewer: "reviewer",
  reviews: "reviews",
  reviewsList: "reviews list",
  search: "search",
  seeAll: "See all...",
  send: "send",
  selectBook: "select book",
  shopBook: "shop book",
  shopBooks: "shop more books",
  shopGifts: "shop more gifts",
  subscribeTo: "subscribe to our newsletter",
  subscribe: "subscribe",
  subtotal: "subtotal",
  title: "Title",
  titleShow: "showing title",
  total: "total amount",
  weight: "weight",
  width: "width",
  year: "Year",
  yourMsg: "your message",
  noProducts: "No products match"
};
const croatian = {
  about: "opis",
  aboutUs: "O nama",
  add: "dodaj",
  addImage: "Dodaj sliku",
  addToCart: "dodajte u košaricu",
  amount: "količina",
  author: "autor",
  authors: "Autori",
  authorsList: "popis autora",
  back: "nazad",
  book: "knjiga",
  bookCover: "Uvez",
  bookTitle: "naslov knjige",
  books: "knjige",
  booksList: "popis knjiga",
  cart: "košarica",
  cartInfo: "artikli",
  clientCity: "grad",
  clientEmail: "email",
  clientInfo: "client information",
  clientLastName: "prezime",
  clientName: "ime",
  clientPostalCode: "poštanski broj",
  clientStreetName: "ulica",
  clientStreetNumber: "kućni broj",
  clientCityMsg: "Molimo unesite ime grada",
  clientEmailMsg: "Molimo unesite valjanu e-mail adresu",
  clientLastNameMsg: "Molimo unesite vaše prezime",
  clientNameMsg: "Molimo unesite vaše ime",
  clientPostalCodeMsg: "Molimo unesite poštanski broj",
  clientStreetNameMsg: "Molimo unesite ime ulice",
  clientStreetNumberMsg: "Molimo unesite kućni broj",
  confirm: "potvrdi",
  contact: "kontakt",
  content: "Sadržaj",
  date: "datum",
  delete: "izbriši",
  description: "Opis",
  dimensions: "Dimenzije",
  depth: "dužina",
  disclaimer: "Izjava o privatnosti",
  edit: "uredi",
  enterEmail: "unesite vaš email",
  emptyCart: "prazna košara",
  error: "greška",
  errorMsg:
    "Na žalost došlo je do greške, izvješće će biti automatski poslano.",
  free: "Besplatno",
  genInfo: "opće informacije",
  genre: "Žanr",
  giftshop: "giftshop",
  giftshopList: "giftshop popis",
  hardcover: "Tvrdi",
  height: "visina",
  home: "početna",
  image: "Slika",
  images: "Slike",
  infoPages: "info stranice",
  item: "artikl",
  language: "Jezik",
  lastName: "prezime",
  link: "poveznica",
  links: "linkovi",
  list: "popis",
  message: "poruka",
  messages: "poruke",
  more: "više",
  maxAmount: "Najveća količina za narudžbu",
  maxOrder: "Najveća kol.",
  name: "ime",
  newOrder: "Primljena je nova narudžba",
  news: "vijesti",
  newsList: "popis vijesti",
  next: "sljedeća",
  noOfPages: "Broj stranica",
  notFound: "nije pronađena",
  orderHowTo: "Kako naručiti",
  order: "naručite",
  orderNoun: "Narudžba",
  orders: "narudžbe",
  orderTotal: "ukupna narudžba",
  ourBooks: "Naše knjige:",
  ourGiftshop: "Naš giftshop:",
  page: "stranica",
  pageErrMsg:
    " Web izbornik će Vas automatski preusmjeriti na prethodnu stranicu, ukoliko to ne učini, molimo kliknite na 'Nazad'",
  paperback: "Mekani",
  paymentAndShipping: "Plaćanje i dostava",
  pleaseWait: "molimo pričekajte",
  pleaseWaitLoad: "Molimo pričekajte, učitavanje...",
  prev: "prošla",
  price: "Cijena",
  priceTotal: "Ukupna cijena",
  properties: "Karakteristike",
  postalFee: "Poštarina",
  postalFeeLimit: "Poštarina besplatna iznad",
  publisher: "Izdavač",
  purchaseAdress: "unesite adresu za vašu narudžbu",
  rating: "ocijena",
  remove: "makni",
  reset: "resetiraj",
  review: "recenzija",
  reviewer: "autor recenzije",
  reviews: "recenzije",
  reviewsList: "popis recenzija",
  search: "traži",
  seeAll: "Vidi sve...",
  send: "pošalji",
  selectBook: "izaberite knjigu",
  shopBook: "kupiti knjigu",
  shopBooks: "kupite još knjiga",
  shopGifts: "kupite još poklona",
  subscribeTo: "pretplatite se na naš newsletter",
  subscribe: "pretplatite se",
  subtotal: "ukupno",
  title: "Naslov",
  titleShow: "Naslov za prikaz",
  total: "ukupan iznos",
  weight: "težina",
  width: "širina",
  year: "Godina",
  yourMsg: "vaša poruka",
  noProducts: "nema proizvoda"
};

export { english, croatian };
