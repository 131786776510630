export default [
  {
    title: "news #1",
    id: 1,
    text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam dolores adipisci distinctio, eos cum facere! Praesentium eaque fugiat laboriosam excepturi molestiae earum voluptate quasi ab repudiandae quibusdam. Minima, illo itaque.",
    images: [],
    date: "10/08/2010"
  },
  {
    title: "news #2",
    id: 2,
    text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam dolores adipisci distinctio, eos cum facere! Praesentium eaque fugiat laboriosam excepturi molestiae earum voluptate quasi ab repudiandae quibusdam. Minima, illo itaque.",
    images: [],
    date: "10/08/2012"
  },
  {
    title: "news #3",
    id: 3,
    text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam dolores adipisci distinctio, eos cum facere! Praesentium eaque fugiat laboriosam excepturi molestiae earum voluptate quasi ab repudiandae quibusdam. Minima, illo itaque.",
    images: [],
    date: "10/08/2013"
  },
  {
    title: "news #4",
    id: 4,
    text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam dolores adipisci distinctio, eos cum facere! Praesentium eaque fugiat laboriosam excepturi molestiae earum voluptate quasi ab repudiandae quibusdam. Minima, illo itaque.",
    images: [],
    date: "10/08/2014"
  },
  {
    title: "news #5",
    id: 5,
    text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam dolores adipisci distinctio, eos cum facere! Praesentium eaque fugiat laboriosam excepturi molestiae earum voluptate quasi ab repudiandae quibusdam. Minima, illo itaque.",
    images: [],
    date: "10/08/2015"
  },
  {
    title: "news #6",
    id: 6,
    text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam dolores adipisci distinctio, eos cum facere! Praesentium eaque fugiat laboriosam excepturi molestiae earum voluptate quasi ab repudiandae quibusdam. Minima, illo itaque.",
    images: [],
    date: "10/08/2017"
  },
  {
    title: "news #7",
    id: 7,
    text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam dolores adipisci distinctio, eos cum facere! Praesentium eaque fugiat laboriosam excepturi molestiae earum voluptate quasi ab repudiandae quibusdam. Minima, illo itaque.",
    images: [],
    date: "10/08/2019"
  }
].reverse();
