export const ADD_ITEM = "ADD_ITEM";
export const REMOVE_ITEM = "REMOVE_ITEM";
export const TOGGLE_ITEM_AMOUNT = "TOGGLE_ITEM_AMOUNT";
export const CLEAR_CART = "CLEAR_CART";
export const COUNT_TOTALS = "COUNT_TOTALS";
export const OPEN_MODAL = "OPEN_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";
export const UPDATE_CLIENT = "UPDATE_CLIENT";
export const SET_CL_NAME_ERR_TRUE = "SET_CL_NAME_ERR_TRUE";
export const SET_CL_NAME_ERR_FALSE = "SET_CL_NAME_ERR_FALSE";
export const SET_CL_LASTNAME_ERR_TRUE = "SET_CL_LASTNAME_ERR_TRUE";
export const SET_CL_LASTNAME_ERR_FALSE = "SET_CL_LASTNAME_ERR_FALSE";
export const SET_CL_EMAIL_ERR_TRUE = "SET_CL_EMAIL_ERR_TRUE";
export const SET_CL_EMAIL_ERR_FALSE = "SET_CL_EMAIL_ERR_FALSE";
export const SET_CL_ST_NUM_ERR_TRUE = "SET_CL_ST_NUM_ERR_TRUE";
export const SET_CL_ST_NUM_ERR_FALSE = "SET_CL_ST_NUM_ERR_FALSE";
export const SET_CL_ST_NAME_ERR_TRUE = "SET_CL_ST_NAME_ERR_TRUE";
export const SET_CL_ST_NAME_ERR_FALSE = "SET_CL_ST_NAME_ERR_FALSE";
export const SET_CL_CITY_ERR_TRUE = "SET_CL_CITY_ERR_TRUE";
export const SET_CL_CITY_ERR_FALSE = "SET_CL_CITY_ERR_FALSE";
export const SET_CL_POST_CODE_ERR_TRUE = "SET_CL_POST_CODE_ERR_TRUE";
export const SET_CL_POST_CODE_ERR_FALSE = "SET_CL_POST_CODE_ERR_FALSE";
export const SET_CART_ERROR_TRUE = "SET_CART_ERROR_TRUE";
export const SET_CART_ERROR_FALSE = "SET_CART_ERROR_FALSE";
export const RESET_CART = "RESET_CART";
export const ADD_POSTAL_FEE = "ADD_POSTAL_FEE";
export const SET_POST_STATUS = "SET_POST_STATUS";
