export const GET_ITEMS_START = "GET_ITEMS_START";
export const GET_ITEMS_SUCCESS = "GET_ITEMS_SUCCESS";
export const GET_ITEMS_ERROR = "GET_ITEMS_ERROR";
export const GET_SINGLE_ITEM_START = "GET_SINGLE_BOOK_START";
export const GET_SINGLE_ITEM_ERROR = "GET_SINGLE_BOOK_ERROR";
export const GET_SINGLE_GIFT = "GET_SINGLE_GIFT_ID";
export const GET_SINGLE_BOOK = "GET_SINGLE_BOOK_ID";
export const GET_ITEM_DIMENSIONS = "GET_ITEM_DIMENSIONS";
export const GET_BOOK_PROPERTIES = "GET_BOOK_PROPERTIES";
export const UPDATE_SIZE = "UPDATE_SIZE";
export const UPDATE_LENGTH = "UPDATE_LENGTH";
export const UPDATE_LENGTH_SEPARATE = "UPDATE_LENGTH_SEPARATE";
export const UPDATE_NEWS_LENGTH = "UPDATE_NEWS_LENGTH";
export const SET_SINGLE_NEWS = "SET_SINGLE_NEWS";
export const UPDATE_CONTACT_FORM = "UPDATE_CONTACT_FORM";
export const SET_CONT_NAME_ERR_TRUE = "SET_CONT_NAME_ERR_TRUE";
export const SET_CONT_NAME_ERR_FALSE = "SET_CONT_NAME_ERR_FALSE";
export const SET_CONT_EMAIL_ERR_TRUE = "SET_CONT_EMAIL_ERR_TRUE";
export const SET_CONT_EMAIL_ERR_FALSE = "SET_CONT_EMAIL_ERR_FALSE";
export const SET_CONT_FORM_ERR_TRUE = "SET_CONT_FORM_ERR_TRUE";
export const SET_CONT_FORM_ERR_FALSE = "SET_CONT_FORM_ERR_FALSE";
export const RESET_CONTACT_FORM = "RESET_CONTACT_FORM";
export const FETCH_LINKS = "FETCH_LINKS";
export const FETCH_INFOPAGES = "FETCH_INFOPAGES";
